import api from "../axios_service.js";

export async function fetchComentarios(filtros) {
  const response = await api.get(
    `/colaborador/comentarios${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchComentario(id) {
  const response = await api.get(`/colaborador/comentarios/${id}`);
  return response.data.result;
}

export async function postComentario(item) {
  const response = await api.post("/colaborador/comentarios/add", item);
  return response;
}
